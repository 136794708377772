import { Component, computed, inject, input } from '@angular/core';
import { ButtonSolidComponent, ButtonTextComponent } from '@intemp/unijob-ui2';
import { I18NextModule } from 'angular-i18next';
import { NgIf } from '@angular/common';
import { MatchesService } from '../../../core/services/matches/matches.service';
import { MatchFragment } from '../../../graphql/generated';
import { MatchAllowedAction } from '../match.types';
import { GlobalSheetsService } from '../../../shared/modules/global-sheets/global-sheets.service';

@Component({
  selector: 'app-match-action-menu',
  templateUrl: './match-action-menu.component.html',
  standalone: true,
  imports: [ButtonTextComponent, I18NextModule, NgIf, ButtonSolidComponent],
})
export class MatchActionMenuComponent {
  service = inject(MatchesService);
  globalSheetsService = inject(GlobalSheetsService);
  match = input.required<MatchFragment>();
  buttonStyle = input<'solid' | 'text'>('text');
  isInSheet = input<boolean>(false);

  actions = computed(() => {
    const match = this.match();
    const { primaryActions, sheetActions, secondaryActions } =
      this.service.getMatchActionOptions(match, this.isInSheet());

    return {
      primaryActions,
      secondaryActions,
      sheetActions,
    };
  });

  hasSeparator = computed(() => {
    const actions = this.actions();
    return (
      actions.primaryActions.length > 0 && actions.secondaryActions.length > 0
    );
  });

  handleActionClick(action: MatchAllowedAction) {
    if (action.action) {
      action.action();
    }
  }
}
